/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({location, prevLocation}) => {
    console.log("new pathname", location.pathname);
    console.log("old pathname", prevLocation ? prevLocation.pathname : null)
};

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    window.scrollTo(0, 0)
    return false
}
